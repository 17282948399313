
/* Added Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Custom styles for smooth transitions */
* {
  transition: all 0.2s ease-in-out;
}

/* Prevent horizontal scroll on mobile */
html, body {
  overflow-x: hidden;
  width: 100%;
}

/* Adjust content padding for fixed header */
main {
  min-height: calc(100vh - 5rem); /* Subtract header height */
  padding-top: 5rem; /* Add padding equal to header height */
}

/* Responsive font sizes */
@layer base {
  html {
    font-size: 14px;
    @screen sm {
      font-size: 15px;
    }
    @screen md {
      font-size: 16px;
    }
  }
}
